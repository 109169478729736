<script>
export default {
    name: 'Footer',
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
        };
    }
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">AdaptaEasy - StoreManager © 2023 Vysio s.r.l. </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Crafted and designed by JackGreek | TheGINLabs
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
