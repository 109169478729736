export const menuVariants = {
    "StoreManager": [
        // {
        //     id: 1,
        //     label: "menuitems.menu.text",
        //     isTitle: true
        // },
        {
            id: 1,
            label: "Dashboard",
            icon: "uil-dashboard",
            link: "/"
        },
        {
            id: 2,
            label: "Clienti",
            icon: "uil-users-alt",
            link: "/store/patients"
        },
        {
            id: 3,
            label: "Operatori",
            icon: "uil-house-user",
            link: "/store/doctors"
        },
        {
            id: 4,
            label: "Documenti",
            icon: "uil-file-alt",
            link: "/store/doctors"
        },
        // {
        //     id: 4,
        //     label: "Sistemi",
        //     icon: "uil-laptop",
        //     link: "/store/doctors"
        // },
        {
            id: 5,
            label: "Esci",
            icon: "uil-sign-out-alt",
            link: "/logout"
        },
    ],
    "Doctor": [
        // {
        //     id: 1,
        //     label: "menuitems.dashboard.text",
        //     icon: "uil-home-alt",
        //     link: "/"
        // },
        {
            id: 1,
            label: "Clienti",
            icon: "uil-user-circle",
            link: "/store/patients"
        },
        {
            id: 5,
            label: "Esci",
            icon: "uil-sign-out-alt",
            link: "/logout"
        },
        // {
        //     id: 3,
        //     label: "Operatori",
        //     icon: "uil-house-user",
        //     link: "/doctors"
        // },
        // {
        //     id: 4,
        //     label: "Sistemi",
        //     icon: "uil-laptop",
        //     link: "/doctors"
        // },
    ],
    "Admin": [
        // {
        //     id: 1,
        //     label: "menuitems.menu.text",
        //     isTitle: true
        // },
        // {
        //     id: 1,
        //     label: "Dashboard",
        //     icon: "uil-home-alt",
        //     link: "/admin"
        // },
        {
            id: 2,
            label: "Aziende",
            icon: "uil-building",
            link: "/admin/customers"
        },
        // {
        //     id: 3,
        //     label: "Licenze",
        //     icon: "uil-invoice",
        //     link: "/admin"
        // },
        {
            id: 5,
            label: "Esci",
            icon: "uil-sign-out-alt",
            link: "/logout"
        },
        // {
        //     id: 3,
        //     label: "Operatori",
        //     icon: "uil-house-user",
        //     link: "/doctors"
        // },
        // {
        //     id: 4,
        //     label: "Sistemi",
        //     icon: "uil-laptop",
        //     link: "/doctors"
        // },
    ],
}
